import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { UsuarioService } from '@core/services/usuario.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    user2: any;
    constructor(public userblockService: UserblockService,
                public _usuarioService: UsuarioService) {

        this.user = {
            picture: 'assets/img/user/01.jpg'
        };
    }

    ngOnInit() {
        this.getName();
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    getName() {
        this.user2 = this._usuarioService.getUsuario();
        console.log(this.user2);
    }



}
