import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UsuarioService } from '@core/services/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recupero',
  templateUrl: './recupero.component.html',
  styleUrls: ['./recupero.component.scss']
})
export class RecuperoComponent implements OnInit {

  valForm: FormGroup;
  showSpinner: boolean = false;

  constructor(public settings: SettingsService, fb: FormBuilder,
    private router: Router,
    public usuarioService: UsuarioService) {
    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      console.log('Valid!');
      console.log(value);
      this.recupero(value);
    }
  }

  recupero(dataSend) {
    console.log('dataSend', dataSend);
    this.usuarioService.recupero(dataSend)
      .subscribe(result => {
        if (result) {
          this.showSpinner = false;
          this.router.navigate(['/usuario/ingreso']);
        }
      },
        error => {
          this.showSpinner = false;
        }
      );
  }

  ngOnInit() {
  }

}
