import { CampaignMessage } from "./CampaigMessages";
import { CampaignConfiguration } from "./CampaignConfiguration";
import { Campaign_Segment } from "./Campaign_Segment";
import { AccionEntrante } from "./AccionEntrante";

export class Campania1 {
    id: number;
    name: string;
    DateFrom: Date;
    DateTo: Date;
    CompanyId: number;
    Answer: string;
    Enabled: number;
    userId?: number;
    TipoMensajeId: number;

    // No mapeado
    segm?: string;
    traceId?: number;

    CampaignMessages: CampaignMessage[] = [];
    CampaignConfigurations: CampaignConfiguration[] = [];
    Campaign_Segments: Campaign_Segment[] = [];
    AccionEntrante: AccionEntrante[] = [];

    constructor() {
    }
}