import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SegmentoService } from '@core/services/segmento.service';
import { BaseDeDatos } from '@core/models/BaseDeDatos';
import { TraceService } from '@core/services/trace.service';
import { Trace } from '@core/models/Trace';
import { TraceDetails } from '@core/models/TraceDetails';
import { Segment } from '@core/models/Segment';
const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
import { ExportToCSV } from '@molteni/export-csv';
import * as FileSaver from 'file-saver';
import { UsuarioService } from '@core/services/usuario.service';
import { Localidades } from '@core/models/Localidad';
import { AppComponent } from 'app/app.component';
import { switchMap } from 'rxjs/operators';
import { exists } from 'fs';
import { of } from 'rxjs';


export interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-nueva-base',
  templateUrl: './nueva-base.component.html',
  styleUrls: ['./nueva-base.component.scss']
})


export class NuevaBaseComponent implements OnInit {
  exportToCSV = new ExportToCSV();
   myForm: FormGroup;
  segment = new Segment();
  selected: any;
  csv;
  csv2;
  loading = false;
  prueba = new BaseDeDatos();
  baseDeDatos = new BaseDeDatos();
  checked = false;
  color = 'primary';
  traces: Trace[] = [];
  traceDetails: TraceDetails[] = [];
  arraySubido: any[] = [];
  arrayCop: any[] = [];
  archivoCargado = false;
  trace1: any;
  textNormalizar = 'No Normalizar';
  csvData: any;
  data: any = [{
    Telefono: null,
    Email: null,
    Nombre: null,
    Apellido: null,
    Localidad: null,
    Provincia: null,
    Sexo: null,
    Adicional1: null,
    Adicional2: null,
    Adicional3: null,
    Adicional4: null,
    Adicional5: null
  }];

  public uploader: FileUploader = new FileUploader({ url: URL });
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  public fileString;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  constructor(private router: Router, public fb: FormBuilder,
    private _snackBar: MatSnackBar, private segmento: SegmentoService, private _traceService: TraceService,
    private _usuarioService: UsuarioService,
    private appComponent: AppComponent,
    private service: SegmentoService) { // Inyecta el componente padre) {
    this.myForm = this.fb.group({
      segmento: ['', [Validators.required, Validators.maxLength(30)]],
      descripcion: [''],
      trazaCombo: ['', [Validators.required]],
    });

  }

  ngOnInit() {
    this.obtenerTraces();
  }

  downloadCSV() {
    this.segmento.getCSVModel().subscribe(data => {
      console.log(data);
      const blob = new Blob([data], { type: 'application/csv' });
      console.log(blob);
      FileSaver.saveAs(data, 'FormatoMovio.csv');     
    });
  }

  nuevaTraza() {
    console.log('Nueva Base');
    this.router.navigate(['/bases/nuevaTraza'])
  }

  descargarFormato() {
    console.log('Descargar formato..');
  } 
  changeListener($event): void {
    this.csv = null;
    if (this.selected.id === 0) {
      this._snackBar.open('Error, debe seleccionar una traza correcta!', 'Aceptar', {
        duration: 2000,
      });
      return;
    }
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    this.csv = file;


    // obtenemos los ultimos 3 caracteres (CSV) para validar archivo
    const ultimosCaracteres = (file.name).substr(-3);
    if (ultimosCaracteres !== 'csv') {
      this._snackBar.open('Formato incorrecto!', '', {
        duration: 2000,
        panelClass: ['test']
      });
      return;
    }
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.csvData = myReader.result;
      const resultado = this.leerEncabezado(myReader.result);
      const rta = this.validarCantidadCaracteres(this.csvData);
      
      if (rta == -1) {
        this._snackBar.open('Error, la cantidad de caracteres por columnas debe ser 40!', '', {
          duration: 3000,
          panelClass: ['test']
        });
        
        return;
      }
      if (rta == -2) {
        this._snackBar.open('Error, la base no esta normalizada!', '', {
          duration: 3000,
          panelClass: ['test']
        });
        
        return;
      }
      if (resultado === false) {
        file == null;
        this.archivoCargado = false;
      } else {
        this.archivoCargado = true;
      }
      this.fileString = myReader.result;
    };
    myReader.readAsText(file);
  }

  validarCantidadCaracteres(param) {
    
    const csvRecordsArray = (<string>this.csvData).split(/\r\n|\n/);
    console.log(csvRecordsArray);

    const headersRow = this.getHeaderArray(csvRecordsArray);
    const rta = this.validamosCantidadCaracteres(csvRecordsArray, headersRow.length);
    // Si es galicia le permitimos pasar la validacion
    const idCompany = this._usuarioService.getUsuario().companyId.toString();
    console.log(idCompany);
    if (idCompany === '8') {
      return true;
    } else {
      console.log('hola');
    }
    return rta;
  }


  validamosCantidadCaracteres(csvRecordsArray: any, headerLength: any) {
    const csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const curruntRecord = (<string>csvRecordsArray[i]).split(';');
      if (curruntRecord.length === headerLength) {
        if(curruntRecord[0].length > 10) return -2;
        for (let index = 0; index < curruntRecord.length; index++) {
          if (curruntRecord[index].length > 40) {
            return -1;
          }
        }
      }
    }
    return 0;
  }

  leerEncabezado(param): boolean {

    const arrayCompleto = param.split('\n');
    let arrayEnzabezado = arrayCompleto[0];
    arrayEnzabezado = arrayEnzabezado.substr(0, arrayEnzabezado.length - 1);
    const columnas = arrayEnzabezado.split(';');

    console.log(columnas);
    console.log(this.arraySubido);
   // this.pruebaa(columnas , this.arraySubido);

    if (this.arraysEqual(columnas, this.arraySubido) === false) {
      this._snackBar.open('Error, el CSV seleccionado no se corresponde a la traza!', '', {
        duration: 3000,
        panelClass: ['test']
      });
    }
   return this.arraysEqual(columnas, this.arraySubido);
  }


  ordenarArraySubido(columnas) {

  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(';');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  guardarBase() {
    // Llama a existeNombre antes de putSegment
    
    if (this.archivoCargado == false)
      {
        this._snackBar.open('Debe cargar un archivo', 'Aceptar', {
          duration: 2000,
        });
      }
          else{
          this.appComponent.loading = true;
          this.baseDeDatos.Name = this.myForm.value.segmento;
          this.baseDeDatos.UserId = this._usuarioService.getUsuario().id;
          this.baseDeDatos.CompanyId = this._usuarioService.getUsuario().companyId;
          this.baseDeDatos.CreationDate = new Date();
          this.baseDeDatos.Description = this.myForm.value.descripcion;
          this.baseDeDatos.TraceId = this.selected.id;
          this.normalizarBase();
        }
      
        this.service.existeNombre(this.baseDeDatos.Name,0).pipe(
          switchMap((exists:boolean) =>{
            if (exists) {
              this.appComponent.loading=false;
              this._snackBar.open('El nombre de esta base ya existe, elija otro', 'Aceptar',{
                duration: 2000,
              });
              return of(null);
            }

            // ARCHIVO CSV
            const formData: FormData = new FormData();
            formData.append((this.baseDeDatos.TraceId).toString(), this.csv, (this.baseDeDatos.Name).concat('.csv'));
            formData.append('prueba2', this.csv, 'prueba2');
            formData.append('req', JSON.stringify(this.baseDeDatos));
            formData.append('req1', this.checked.toString());

            return this.segmento.enviarExcel(formData, this.baseDeDatos);
          })
        ).subscribe(data=> {
          if (data){
            this.appComponent.loading = false;
            this.router.navigate(['/bases']);
          }
        },(err) => {
          this.appComponent.loading = false;
          if (err.status === 400) {
            this._snackBar.open('Ocurrio un problema, comunicarse con el proveedor!', '', {
              duration: 2000,
              panelClass: ['test']
            });
          } else if (err.status === 600) {
            this._snackBar.open('Falta normalizar el archivo .csv!', '', {
              duration: 4000,
              panelClass: ['test']
            });
            return;
          } else {
            this._snackBar.open('Error al conectarse con el servidor!', '', {
                duration: 2000,
                panelClass: ['test']
            });
          }
          this.router.navigate(['/bases']);
        });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  obtenerTraces() {
    this._traceService.getTracesWithDetail().subscribe(data => {
      this.traces = data;
      this.selected = 0;
      this.appComponent.loading = false;
    }, (err) => {
      this.appComponent.loading = false;
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    })
  }

  cancelar() {
    this.router.navigate(['/bases'])
  }

  select(item) {
    console.log(item)
    this.archivoCargado = false;
    this.arraySubido = [];
    if (item == 0) {
      this.traceDetails = [];
      return;
    }
    console.log(item.id);
    item.traceDetails.forEach(element => {
      this.arraySubido.push(element.columnNameFile);
    });
    console.log("Este es el array subido:" + this.arraySubido);
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  changed() {
    if (this.checked) {
      this.checked = false
      this.textNormalizar = "No Normalizar";
    }
    else {
      this.checked = true
      this.textNormalizar = "Normaizar";
    }
    console.log(this.checked)
  }

  normalizarBase() {
    console.log(this.csvData)
    let csvRecordsArray = (<string>this.csvData).split(/\r\n|\n/);
    console.log(csvRecordsArray);

    let headersRow = this.getHeaderArray(csvRecordsArray);
    let body = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
    const csvData = this.jsonToCsv(body, headersRow);

    //let csvData = this.ConvertToCSV(body);
    var blob = new Blob([csvData], { type: 'text/csv' });
    this.csv2 = blob;
  }


  normalizarNumero(tel) {
    tel = this.limpiarNumero(tel);
    return tel;
  }

  limpiarNumero(string) {
    var tmp = string.split('');
    var map = tmp.map(function (current) {
      if (!isNaN(parseInt(current))) {
        return current;
      }
    });
    var numbers = map.filter(function (value) {
      return value != undefined;
    });

    return numbers.join('');
  }

  deleteFirstCharacter(telefono) {
    if (telefono.charAt(0) == 0) {
      telefono = telefono.substring(1, telefono.length);
    }
    return telefono;
  }

  delete54(telefono) {
    if (telefono.substr(0, 2) == 54) {
      telefono = telefono.substring(2, telefono.length);
    }
    return telefono;
    //console.log(telefono)
  }
  delete549(telefono) { // G.A
    if (telefono.substr(0, 3) == 549) {
      telefono = telefono.substring(3, telefono.length);
    }
    return telefono;
  }

  eliminarQuince(numero) {
    let prefijo;
    prefijo = this.obtenerPrefijo(numero);

    let loc = Localidades.find(localidad => localidad.prefijo === prefijo);
    console.log(loc);
    let cantidadCaracteres = 0;
    if (loc != null) {
      cantidadCaracteres = loc.prefijo.length;
    }

    switch (cantidadCaracteres) {
      case 2:
        if (numero.substring(2, 4) == '15') {
          let prefijoCop = numero.substring(0, 2);
          let numeroCop = numero.substring(4, numero.length);
          return prefijoCop.concat(numeroCop);
        }
      case 3:
        if (numero.substring(3, 5) == '15') {
          let prefijoCop = numero.substring(0, 3);
          let numeroCop = numero.substring(5, numero.length);
          return prefijoCop.concat(numeroCop);
        }
      case 4:
        if (numero.substring(4, 6) == '15') {
          let prefijoCop = numero.substring(0, 4);
          let numeroCop = numero.substring(6, numero.length);
          return prefijoCop.concat(numeroCop);
        }
      default:
        break;
    }
    return numero;
  }

  obtenerPrefijo(numero) {
    let prefijo;
    let prefijoLocalidad: string[] = [];
    let loc;
    let cuatroCaracteres = numero.substring(0, 4);
    let tresCaracteres = numero.substring(0, 3);
    let dosCaracteres = numero.substring(0, 2);
    prefijoLocalidad.push(cuatroCaracteres);
    prefijoLocalidad.push(tresCaracteres);
    prefijoLocalidad.push(dosCaracteres);

    for (let index = 0; index < prefijoLocalidad.length; index++) {
      //const element = prefijoLocalidad[index];
      loc = Localidades.find(prefijo => prefijo.prefijo === prefijoLocalidad[index]);
      if (loc != null) {
        prefijo = loc.prefijo;
        return prefijo;
      }
    }

    if (loc == null) {
      return prefijo;
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(';');
      if (curruntRecord.length == headerLength) {
        let telefono;
        telefono = curruntRecord[0].trim();
        if(this.checked) {
          curruntRecord[0] = this.normalizarNumero(telefono);
          curruntRecord[0] = this.deleteFirstCharacter(curruntRecord[0]);
          curruntRecord[0] = this.delete549(curruntRecord[0]);
          curruntRecord[0] = this.delete54(curruntRecord[0]);
          curruntRecord[0] = this.eliminarQuince(curruntRecord[0]);
        }
        csvArr.push(curruntRecord);
      }
    }
    return csvArr;
  }

  jsonToCsv(data: any[],headersRow, separador: string = ';') {
    const keys = Object.keys(headersRow[0]);
    const csvContent =
    headersRow.join(separador) +
      '\n' +
      data.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separador);
      }).join('\n');

    return csvContent;
  }

}

