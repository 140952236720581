import { LayoutComponent } from '../layout/layout.component';
import { IngresoComponent } from './usuario/ingreso/ingreso.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { RegistroComponent } from './usuario/registro/registro.component';
import { RecuperoComponent } from './usuario/recupero/recupero.component';


export const routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: './home/home.module#HomeModule', canActivate: [AuthGuard] },
            { path: 'bases', loadChildren: './bases/bases.module#BasesModule', canActivate: [AuthGuard] },
            { path: 'campanias', loadChildren: './campanias/campanias.module#CampaniasModule', canActivate: [AuthGuard] },
            { path: 'estadisticas', loadChildren: './estadisticas/estadisticas.module#EstadisticasModule', canActivate: [AuthGuard] },

            // { path: 'planesVigentes', loadChildren: './planes-vigentes/planes-vigentes.module#PlanesVigentesModule', canActivate: [AuthGuard] },

            { path: 'normalizador', loadChildren: './normalizador/normalizador.module#NormalizadorModule', canActivate: [AuthGuard] },
            { path: 'material', loadChildren: './material/material.module#MaterialModule', canActivate: [AuthGuard] },
           // { path: 'cliente', loadChildren: './cliente/cliente.module#ClienteModule', canActivate: [AuthGuard] },
            { path: 'traza', loadChildren: './traces/traces.module#TraceModule', canActivate: [AuthGuard] },
            { path: 'usuarios', loadChildren: './gestion-usuarios/gestion-usuarios.module#GestionUsuariosModule',
                                             canActivate: [AuthGuard] },
            { path: 'bandejaEntrada', loadChildren: './bandeja-entrada/bandeja-entrada.module#BandejaEntradaModule',
                                            canActivate: [AuthGuard] },
            { path: 'estadisticasAdmin', loadChildren: './estadisticas-admin/estadisticas.module#EstadisticasAdminModule',
                                            canActivate: [AuthGuard] },
            { path: 'empresasAdmin', loadChildren: './empresas/empresas.module#EmpresasModule', canActivate: [AuthGuard] },
            { path: 'campaniasAdmin', loadChildren: './campanias-admin/campaniasAdmin.module#CampaniasAdminModule',
                                            canActivate: [AuthGuard] },
            { path: 'bandejaEntradaAdmin', loadChildren: './bandeja-entrada-admin/bandeja-entrada-admin.module#BandejaEntradaAdminModule',
                                            canActivate: [AuthGuard] },
            { path: 'enviadoresAdmin', loadChildren: './Enviadores/enviadores.module#EnviadoresAdminModule', canActivate: [AuthGuard] },
            { path: 'facturacionAdmin', loadChildren: './facturacion-admin/facturacion-admin.module#FacturacionAdminModule',
                                    canActivate: [AuthGuard] },
            { path: 'pruebaAdmin', loadChildren: './prueba-admin/prueba-admin.module#PruebaAdminModule', canActivate: [AuthGuard] },
            { path: 'planesAdmin', loadChildren: './planes/planes.module#PlanesModule', canActivate: [AuthGuard] }
        ]
    },
    { path: 'usuario/ingreso', component: IngresoComponent },
    { path: 'usuario/registro', component: RegistroComponent },
    { path: 'usuario/recupero', component: RecuperoComponent },
    { path: '**', redirectTo: 'home' }
];
