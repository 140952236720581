import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'segmentAsociate'
})
export class SegmentAsociatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(value.asociado > 1) {
      return "SI"
    }else {
      return "NO"
    }
  }

}
