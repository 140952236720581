import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';


registerLocaleData(localeEsAr, 'es-Ar');

export function createTranslateLoader(handler: HttpBackend) {
    const http = new HttpClient(handler);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
       
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,
        SharedModule,
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpBackend]
            }
        }),
    ],
    exports: [ ],
    providers: [ToasterService,
        { provide: LOCALE_ID, useValue: 'es-Ar' }],
    bootstrap: [AppComponent]
})
export class AppModule { }
