export const resources = {
        Comentarios: 'Archivo json con la definición de rutas relativas de los recursos de la api',
        Cliente: {
            url: 'Cliente'
        },
        Producto: {
            url: 'Producto'
        },
        Factura: {
            url: 'Factura'
        },
        Usuario: {
            url: 'usuario'
        }
}