import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignsService } from '@core/services/campaigns.service';
import { MatSnackBar } from '@angular/material';
import { ExportExcelService } from '@core/services/export-excel.service';
import { ReporteBandejaEntrada } from '@core/models/ReporteBandejaEntrada';
import { UsuarioService } from '@core/services/usuario.service';
import { User } from '@core/models/user';
import { Observable, of } from "rxjs";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-ver-campaign',
  templateUrl: './ver-campaign.component.html',
  styleUrls: ['./ver-campaign.component.scss']
})
export class VerCampaignComponent implements OnInit {
  campaigns: any = {};
  loading: boolean;
  user: User;

  constructor(private route: ActivatedRoute, private _campaignService: CampaignsService,
    private _snackBar: MatSnackBar, private _excelService: ExportExcelService,
    private _usuarioService: UsuarioService) { }

  ngOnInit() {
    this.getDatos();
    this.getIdCompany();
  }

  getDatos() {
    this.loading = true;
    const id = +this.route.snapshot.paramMap.get('id');
    this._campaignService.getCampaignById(id).subscribe(data => {
      this.loading = false;
      this.campaigns = data;
    }, (err) => {
      this.loading = false;
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    })
  }

  descargarReporte() {
    this.loading = true;
    const idCampaign = +this.route.snapshot.paramMap.get('id');
    this._campaignService.getReportBandejaEntrada(idCampaign).subscribe(data => {
      let repor: any[] = data;
      repor =  this.reporteSMSCorto(repor);
      console.log(repor);
      this._excelService.exportCsv(repor, 'ReporteBandejaEntrada.csv')
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    });
  }

  reporteSMSCorto(reporte: any[]) {

    if (this.campaigns.tipoMensajeId === 1) {
      return reporte;
    } else {
      const reporteProcesado = reporte.map(function (obj) {
        return { Celular: obj.celular, Email: obj.email, Location: obj.location, Sex: obj.sex,
                Aditional1: obj.aditional1, Aditional2: obj.aditional2, Aditional3: obj.aditional3,
                Aditional4: obj.aditional4, FechaEnvio: obj.fechaEnvio, Sms: obj.sms,Respuesta: obj.Respuesta,FechaRespuesta: obj.FechaRespuesta,Estado :obj.Estado  };
      });
      console.log(reporteProcesado);
      return reporteProcesado;
    }
  }

  getIdCompany() {
    this.user = this._usuarioService.getUsuario();
  }

  reporteGalicia() {
    let text = "";
    const idCampaign = +this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this._campaignService.getReportGalicia(idCampaign).subscribe(data => {
      this.loading = false;
      const repor: ReporteBandejaEntrada[] = data;
      text = this.armarTxtGaliica(repor);
      var txt = new Blob([text], { type: 'text/plain;charset=utf-8' });
      saveAs(txt, 'text.txt');
    }, (err) => {
      this.loading = false;
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    })

  }

  armarTxtGaliica(reporte: any[]) {
    let txt = ""
    reporte.forEach(element => {
      txt += element.aditional1 + ";";
      txt += element.aditional2 + ";";
      txt += element.celular + ";";
      if (element.activo == 0) {
        txt += "ENVDO" + "\n";
      } else {
        txt += "AENVIAR" + "\n";
      }

    });
    return txt
  }
}



