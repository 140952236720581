import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }


  exportCsv(json: any[], filename) {

    return new Promise((resolve, reject) => {

      if (json.length === 0 || json === null || json === undefined) {

        alert('Error al descargar!');
        reject(true);
      } else {
        const csvData = this.jsonToCsv(json);
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, filename);
        resolve(true);
      }
    });
  }

  jsonToCsv(data: any[], separador: string = ';') {
    const keys = Object.keys(data[0]);
    const csvContent =
    keys.join(separador) +
      '\n' +
      data.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separador);
      }).join('\n');
    return csvContent;
  }
}



