import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UsuarioService } from '@core/services/usuario.service';
import { Router } from '@angular/router';
import { User } from '@core/models/user';
import { LoginService } from '@core/services/login.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.scss']
})
export class IngresoComponent implements OnInit {
  loading: boolean = false;
  valForm: FormGroup;
  showSpinner: boolean = false;

  constructor(public settings: SettingsService,
    private router: Router,
    fb: FormBuilder,
    public usuarioService: UsuarioService,
    public _loginService: LoginService,
    private _snackBar: MatSnackBar) {

    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required]
    });

  }

  ngOnInit() {

  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      let u: User = { email: value.email, password: value.password };
      this.login(u, value);
    } else {
      alert("Error en la validacion");
    }
  }

  login(dataSend, value) {
    this.loading = true;
    this._loginService.validarUsuario(dataSend)
      .subscribe(result => {
        console.log(result);
        this.loading = false;
        this.usuarioService.setUserLoggedIn(result);
        this.router.navigate(['/']);
        /* if (result == null) {
          this._snackBar.open('Error en la validacón!', '', {
            duration: 2000,
            panelClass: ['test']
          });
          this.valForm.reset();
        } else {
          this.router.navigate(['/']);
          this.usuarioService.setUserLoggedIn(result);
        } */

      }, (err) => {
        this.loading = false;
        console.log(err);
        if (err.status == 400) {
          this.mensajeError("Usuario o contraseña incorrecta!");
          /* this._snackBar.open('Usuario o contraseña incorrecta!', '', {
            duration: 2000,
            panelClass: ['test']
          }); */
        } else {
          this._snackBar.open('Error al conectarse con el servidor!', '', {
            duration: 2000,
            panelClass: ['test']
          });
        }
      });
  }

  mensajeError(text) {
    this._snackBar.open(text, '', {
      duration: 2000,
      panelClass: ['test']
    });
  }
}
