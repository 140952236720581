import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { UsuarioService } from '@core/services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private usuarioService: UsuarioService, private router: Router) {
  }

/*   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.usuarioService.getUsuarioLogueado()) {
      this.router.navigate(['/usuario/ingreso']);
    }
    return this.usuarioService.getUsuarioLogueado();
  } */


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.usuarioService.getUsuarioLogueado()) {
      this.router.navigate(['/usuario/ingreso']);
      console.log("sin token");
      return false;
    }else {
      console.log("con token");
      return true;
    }
  }

  
}