import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseDeDatos } from '@core/models/BaseDeDatos';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

const httpOptions2 = {
  headers: new HttpHeaders({
    'Content-Type': 'multipart/form-data',
    'Authorization': 'my-auth-token'
  })
};

let options_ : any = {
  headers: new HttpHeaders({
      "Content-Type": "application/json", 
      "Accept": "application/json"
  })
}



@Injectable({
  providedIn: 'root'
})


export class SegmentoService {
  prueba = new BaseDeDatos();

  constructor(public http: HttpClient, public _usuarioService: UsuarioService) { }


  postSegment(prueba): Observable<any> {
    return this.http.post(enviorment+'Segment', prueba,  httpOptions );
  }

  enviarExcel(excel,prueba): Observable<any> {
  /*   let idCompany = this._usuarioService.getUsuario().company.id;
    let idUser = this._usuarioService.getUsuario().id; */
   return this.http.post(enviorment+'UploadBase/Upload',excel);
  }


  getSegmentos(): Observable<any> {
    let idCompany = this._usuarioService.getUsuario().companyId;
    let token = this.getToken();
    return this.http.get(enviorment+'Segment/GetSegmentGrid/' + idCompany, { headers: token});
  }

  deleteSegment(index): Observable<any> {
    let token = this.getToken();
    return this.http.delete(enviorment+'Segment/' + index, { headers: token});
  }

  putSegment(id: number,segment: any): Observable<any> {
    let token = this.getToken();
    return this.http.put(enviorment+'Segment/'+ id, segment, { headers: token});
  }

   putSegmentByNombre(id,nombre,descripcion): Observable<any> {
    return this.http.put(enviorment+'Segment/'+ id +'/'+nombre+'/'+descripcion, httpOptions);
  }

  getSegmentosByIdTrace(id): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment+'Segment/SegmentByIdTraza/' + id, { headers: token});
  }

  getCSVModel(): Observable<any> {
 
    return this.http.get(enviorment+'UploadBase/FormatoMovio',{ responseType: 'blob'});
  }

  descargarCSV(id): Observable<any> {  

    return this.http.get(enviorment + 'Segment/GetCsvById/' + id,{ responseType: 'blob'}, );
  }

  // Gonn. A
  descargarBaseNor(id): Observable<any>{

    return this.http.get(enviorment + 'Segment/GetBaseNorById/' + id);

  }

  getToken() {
    let var12 = JSON.parse(localStorage.getItem('token'));
    let tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer '+ var12});
    return tokenHeader1;
  }

  existeNombre(name: string, idSegment: number): Observable<boolean> {
    let idCompany = this._usuarioService.getUsuario().companyId;
    let token = this.getToken();
    let url = `${enviorment}Segment/existeNombre/${name}/${idCompany}/${idSegment}`;
    
    return this.http.get<boolean>(url, { headers: token });
  }

}
