import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '@shared/models/usuario.model';
import { ApiService } from '@core/http/api.service';
// import * as resources from 'src/app/configs/apiResources.json';
import { resources } from '@configs/api-resources.config';
import { User } from '@core/models/user';
import * as jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root'
})

export class UsuarioService extends ApiService<Usuario> {

    formData: Usuario;
    usuarioLogueado = false;

    private isUserLoggedIn;
    public usserLogged: User;

    constructor(http: HttpClient) {
        super(http, resources.Usuario.url);
        this.isUserLoggedIn = false;
    }

    login(data) {
        return this.usuarioLogin(this.formData);
    }

    registro(data) {
        return this.usuarioRegistro(this.formData);
    }

    recupero(data) {
        return this.usuarioRecupero(this.formData);
    }

    getUsuarioLogueado() {
        return this.usuarioLogueado;
    }
    setUsuarioLogueado() {
        this.usuarioLogueado = true;
    }

    setUserLoggedIn(user) {
        this.usuarioLogueado = true;
        this.isUserLoggedIn = true;
        this.usserLogged = user;
      //  localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(user.token));
        localStorage.setItem('expiration', JSON.stringify(user.expiration));
        let token = JSON.parse(localStorage.getItem('token'));
        // localStorage.setItem('currentUser', JSON.stringify(this.getDecodedAccessToken(token)));
    }

    getUserLoggedIn() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    logOut() {
        this.usuarioLogueado = false;
        localStorage.setItem('currentUser', '');
        localStorage.setItem('token', '');
        localStorage.setItem('expiration', '');
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
      }

      getUsuario(): User {
        try {
            return jwt_decode(JSON.parse(localStorage.getItem('token')));
        } catch (Error)
        {
            return null;
        }
      }
}

/*
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Usuario } from '@shared/models/usuario.model';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken') ? localStorage.getItem('authToken') : ''
    })
};

@Injectable({
    providedIn: 'root'
})

export class UsuarioService {
    url: string = environment.ambientes.desarrollo.apiUrlBase;
    usuario: Usuario = new Usuario();
    httpOptions: any = {};
    constructor(private httpClient: HttpClient, private router: Router) {

    }

    login(data: object): Observable<any> {
        httpOptions['observe'] = 'response';
        return this.httpClient.post(this.url + 'login', data, httpOptions)
            .pipe(
                tap(// Log the result or error
                    data => console.log("data", data),
                    error => {
                        console.log("error", error);
                        if (error.status == 500) {
                            this.router.navigate(['/error/server-error']);
                            //el problema es que queda el spinner a la vista :(
                        }
                    }
                ),
                catchError(this.handleError_)
            );
    }


    private handleError_(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            console.log('Backend returned error: ', error);
        }
        return throwError(error);
    };

}
*/