import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '@core/settings/settings.service';
import { UsuarioService } from '@core/services/usuario.service';
import { Router } from '@angular/router';
import { LoginService } from '@core/services/login.service';
import { Companie } from '@core/models/Companie';
import { User } from '@core/models/user';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  valForm: FormGroup;
  passwordForm: FormGroup;
  showSpinner: boolean = false;

  constructor(public settings: SettingsService, fb: FormBuilder,
    private router: Router,
    public usuarioService: UsuarioService, public _loginService: LoginService,private _snackBar: MatSnackBar) {
    let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
    let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

    this.passwordForm = fb.group({
      'password': password,
      'confirmPassword': certainPassword
    });

    this.valForm = fb.group({
      'empresa': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'accountagreed': [null, Validators.required],
      'passwordGroup': this.passwordForm
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    for (let c in this.passwordForm.controls) {
      this.passwordForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      console.log('Valid!');
      console.log(value);
      this.registro(value);
    }
  }

  registro(dataSend) {
    console.log('dataSend', dataSend);
    var company: Companie = {
      id: 0,
      name:dataSend.empresa
    }

    var usuario: User = {
      email: dataSend.email,
      password: dataSend.passwordGroup.password,
      Company: company
    }

    console.log(usuario);
  
    this._loginService.saveUser(usuario).subscribe(data => {
      console.log("volvi");
      this.router.navigate(['/usuario/ingreso']);
      this._snackBar.open('Usuario gurdado con exito!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    }, (err) => {
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
    })

    
    /* this.usuarioService.saveUs(dataSend)
      .subscribe(result => {
        if (result) {
          this.showSpinner = false;
          this.router.navigate(['/usuario/ingreso']);
        }
      },
        error => {
          this.showSpinner = false;
        }
      ); */
  }

  ngOnInit() {
  }

}
