import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';
import { Trace } from '@core/models/Trace';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};
@Injectable({
  providedIn: 'root'
})
export class TraceService {

  constructor(public http: HttpClient, public _usuarioService: UsuarioService) { }
 
  getTraces(): Observable<any> {
    let idCompany = this._usuarioService.getUsuario().companyId;
    let token = this.getToken();
    return this.http.get(enviorment+'Traza/GetTraces/' + idCompany, { headers: token});
  }

  getTracesWithDetail(): Observable<any> {
    let idCompany = this._usuarioService.getUsuario().companyId;
    let token = this.getToken();
    return this.http.get(enviorment+'Traza/TrazaWithDetail/' + idCompany, { headers: token});
  }

  deleteTrace(id): Observable<any> {
    let token = this.getToken();
    return this.http.delete(enviorment+'Traza/' + id, { headers: token});
  }

  postTrace(trace: Trace): Observable<any> {
    trace.userid = this._usuarioService.getUsuario().id;
    trace.CompanyId = this._usuarioService.getUsuario().companyId;
    let token = this.getToken();
    return this.http.post(enviorment+'Traza', trace, { headers: token});
  }

  getToken() {
    let var12 = JSON.parse(localStorage.getItem('token'));
    let tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer '+ var12});
    return tokenHeader1;
  }
}
