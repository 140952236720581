// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ambientes: {
    desarrollo: {
        apiUrlBase: 'http://vocus.local/api/'
    },
    desarrolloLive: {
        /* apiUrlBase: 'http://movio-hub.gitlearning.com.ar/api/' */
       /*  apiUrlBase: 'https://api.exchangeratesapi.io/latest' */
       apiUrlBase: 'https://localhost:44357/api/Prueba'
    },
    testing: {
      apiUrlBase: 'https://api.exchangeratesapi.io/latest'
    },
    desarrolloMock: {
      apiUrlBase: 'assets/mocks/',
      esMock: true
    },
    seleccionado: 'desarrolloLive'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
