import { Component, OnInit } from '@angular/core';
import { Campania1 } from '@core/models/Campania1';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '@core/services/usuario.service';
import { CampaignsService } from '@core/services/campaigns.service';
import { Day } from '@core/models/Day';
import { MatSnackBar } from '@angular/material';
import { formatDate } from '@angular/common';
import { CampaignConfiguration } from '@core/models/CampaignConfiguration';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss']
})
export class EditCampaignComponent implements OnInit {
  loading = false;
  fechaActual: Date = new Date();
  campania1 = new Campania1();
  days: Day[] = [];
  selectedValue: any; // idDia
  mytimeDesde: Date = new Date();
  mytimeHasta: Date = new Date();
  arrayFechaDia: string[] = [];
  ismeridian: boolean = false;

  constructor(private router: Router, 
              public _usuarioService: UsuarioService,
              private _campaignService: CampaignsService, 
              private _snackBar: MatSnackBar, 
              private route: ActivatedRoute) { 
    this.campania1.name = "Tomas"
    this.campania1.DateFrom = new Date();
    this.campania1.DateTo = new Date();
    
  }

  ngOnInit() {
    this.getDays();
    this.setHorariosDefecto();
    this.getCampaignById();
  }

  cancelar() {
    const usuario = this._usuarioService.getUsuario().email;
    console.log(usuario);
    if(usuario == "gerardo@gerardo.com") {
      this.router.navigate(['/campaniasAdmin']);
    }else {
      this.router.navigate(['/campanias'])
    }
  }

  getDays() {
    this.loading = true;
    this._campaignService.getDays().subscribe(data => {
      this.loading = false;
      this.days = data;
    }, (err) => {
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
      this.loading = false;
    });
  }

  setHorariosDefecto() {
    this.mytimeDesde.setHours(9);
    this.mytimeDesde.setMinutes(0);
    this.mytimeHasta.setHours(21);
    this.mytimeHasta.setMinutes(0);
  }

  listo(days) {

    var texto = '';
    if (days.length == 0) {
      this._snackBar.open('Error, debe seleccional al menos un día!', 'Aceptar', {
        duration: 2000,
      });
      return;
    }
    let horaDesde = formatDate(this.mytimeDesde, 'HH:mm a', 'en-US', '-0300');
    let horaHasta = formatDate(this.mytimeHasta, 'HH:mm a', 'en-US', '-0300');
    let hora;
    let index = this.arrayFechaDia.length;
    for (let day of days) {

      texto += day.value.name + " - ";
      // busco si hay otro dia igual en el array de configuracion parcial
      hora = this.campania1.CampaignConfigurations.find(dia => dia.DayId.toString().indexOf(day.value.id) > -1);

      if (hora) {
        // si hay otro dia igual, busco las horas del dia ya cargado y lo comparo con el que quieren cargar
        let dateFrom = new Date();
        let horaFrom = hora.TimeFrom.split(":");
        dateFrom.setHours(horaFrom[0], horaFrom[1], 0, 0);

        let dateTo = new Date();
        let horaTo = hora.TimeTo.split(":");
        dateTo.setHours(horaTo[0], horaTo[1], 0, 0);

        this.mytimeDesde.setSeconds(0, 0);
        this.mytimeHasta.setSeconds(0, 0);

        if (dateFrom <= this.mytimeDesde && dateTo >= this.mytimeDesde || this.mytimeDesde < dateFrom && this.mytimeHasta > dateFrom) {
          this._snackBar.open('El dia ya tiene ya tiene una configuracion en ese rango de horas', 'Aceptar', {
            duration: 3000,
          });
          return;
        }

      }
      var campaingConfiguration: CampaignConfiguration = {
        Id: 0,
        CampaignId: 0,
        TimeFrom: formatDate(this.mytimeDesde, 'HH:mm', 'en-US', '-0300'),
        TimeTo: formatDate(this.mytimeHasta, 'HH:mm', 'en-US', '-0300'),
        DayId: day.value.id,
        index: index
      }
      this.campania1.CampaignConfigurations.push(campaingConfiguration);
    }
    texto += "Desde " + horaDesde.toLocaleLowerCase() + " Hasta " + horaHasta.toLocaleLowerCase();
    this.arrayFechaDia.push(texto);

    // Reseteamos los valores
    this.selectedValue = undefined;
    this.setHorariosDefecto();
  }
  eliminarItem(index) {
    this.arrayFechaDia.splice(index, 1);
    this.campania1.CampaignConfigurations.splice(index, this.campania1.CampaignConfigurations.filter(camp => camp.index == index).length);
  }

  getCampaignById() {
    const id = +this.route.snapshot.paramMap.get('id');

    this._campaignService.getCampaignById(id).subscribe(data => {
      this.loading = false;
      console.log(data);
      this.campania1.id = data.id;
      this.campania1.name = data.name;
      this.campania1.DateFrom = data.dateFrom;
      this.campania1.DateTo = data.dateTo;
      //this.campania1.CampaignConfigurations = data.campaignConfigurations;
      this.campania1.CampaignConfigurations = this.campaignConfigUpperCase(data.campaignConfigurations);
      
      this.setConfiguracionParcial(data.campaignConfigurations);
    }, (err) => {
      this._snackBar.open('Error al conectarse con el servidor!', '', {
        duration: 2000,
        panelClass: ['test']
      });
      this.loading = false;
    });
  }

  setConfiguracionParcial(data) {
    data.forEach( (element, index) => {
      let texto = element.name + " Desde " + element.timeFrom + " Hasta " + element.timeTo;
      element.index = index;
      this.arrayFechaDia.push(texto);
    });
    console.log(data);
  }

  campaignConfigUpperCase(campaignConf){
   /*  let listCampaConf: Array<CampaignConfiguration>; */
    let listCampaConf: CampaignConfiguration[] = [];
    campaignConf.forEach( (element, index) => {
      var campaingConfiguration: CampaignConfiguration = {
        Id: 0,
        CampaignId: 0,
        TimeFrom: element.timeFrom,
        TimeTo: element.timeTo,
        DayId: element.dayId,
        index: index
      }
      listCampaConf.push(campaingConfiguration)
    });
    return listCampaConf;
  };

  updateCampania() {
    console.log("Click!");
    this.loading = true;
  
    // Verifica la existencia del nombre antes de proceder con la actualización
    this._campaignService.existeNombre(this.campania1.name, this.campania1.id).subscribe(existe => {
      if (existe) {
        this._snackBar.open('Ya existe una campaña con ese nombre', '', {
          duration: 2000,
          panelClass: ['test']
        });
        this.loading = false;
      } else {
        // Procede con la actualización si no existe otra campaña con el mismo nombre
        this._campaignService.updateCampaign(this.campania1).subscribe(data => {
          this._snackBar.open('Campaña editada con éxito!', '', {
            duration: 2000,
            panelClass: ['test']
          });
          this.redirect();
          this.loading = false;
        }, (err) => {
          this._snackBar.open('Error al conectarse con el servidor!', '', {
            duration: 2000,
            panelClass: ['test']
          });
          this.loading = false;
        });
      }
    }, (err) => {
      this._snackBar.open('Error al verificar el nombre de la campaña!', '', {
        duration: 2000,
        panelClass: ['test']
      });
      this.loading = false;
    });
  }

  redirect() {
    const usuario = this._usuarioService.getUsuario().email;
    console.log(usuario);
    if(usuario == "gerardo@gerardo.com") {
      this.router.navigate(['/campaniasAdmin']);
    }else {
      this.router.navigate(['/campanias'])
    }
  }
}
