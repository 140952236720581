import { Contacts } from "./Contacts";

export class Segment {
    id: number;
    name: string;
    description: string;
    creationDate: Date;
    url: string;
    userId: number;

    contacts: Contacts[] = [];
    constructor() {
    }
}