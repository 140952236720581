export class Localidad {
    prefijo: string;
    nombre: string;
}

export const Localidades: Localidad[] = [
    {
        prefijo: '11',
        nombre: 'Ciudad Autónoma de Buenos Aires',
    },
    {
        prefijo: '220',
        nombre: 'Buenos Aires, Merlo',
    },
    {
        prefijo: '221',
        nombre: 'Buenos Aires, La Plata',
    },
    {
        prefijo: '223',
        nombre: 'Buenos Aires, Mar del Plata',
    },
    {
        prefijo: '230',
        nombre: 'Buenos Aires, Pilar',
    },
    {
        prefijo: '236',
        nombre: 'Buenos Aires, Junin',
    },
    {
        prefijo: '237',
        nombre: 'Buenos Aires, Moreno',
    },
    {
        prefijo: '249',
        nombre: 'Buenos Aires, Tandil',
    },
    {
        prefijo: '260',
        nombre: 'Mendoza, San Rafael',
    },
    {
        prefijo: '261',
        nombre: 'Mendoza, Mendoza',
    },
    {
        prefijo: '263',
        nombre: 'Mendoza, San Martin',
    },
    {
        prefijo: '264',
        nombre: 'San Juan, San Juan',
    },
    {
        prefijo: '266',
        nombre: 'San Luis, San Luis',
    },
    {
        prefijo: '280',
        nombre: 'Chubut, Trelew',
    },
    {
        prefijo: '291',
        nombre: 'Buenos Aires, Bahia Blanca',
    },
    {
        prefijo: '294',
        nombre: 'Rio Negro Neuquen Chubut, San Carlos de Bariloche',
    },
    {
        prefijo: '297',
        nombre: 'Chubut Santa Cruz, Comodoro Rivadavia',
    },
    {
        prefijo: '298',
        nombre: 'Rio Negro, General Roca',
    },
    {
        prefijo: '299',
        nombre: 'Neuquen Rio Negro La Pampa, Neuquen',
    },
    {
        prefijo: '336',
        nombre: 'Buenos Aires, San Nicolas de los Arroyos',
    },
    {
        prefijo: '341',
        nombre: 'Santa Fe, Rosario',
    },
    {
        prefijo: '342',
        nombre: 'Santa Fe, Santa Fe',
    },
    {
        prefijo: '343',
        nombre: 'Entre Rios, Paraná',
    },
    {
        prefijo: '345',
        nombre: 'Entre Rios, Concordia',
    },
    {
        prefijo: '348',
        nombre: 'Buenos Aires, Bélen de Escobar',
    },
    {
        prefijo: '351',
        nombre: 'Córdoba, Córdoba',
    },
    {
        prefijo: '353',
        nombre: 'Cordoba, Villa Maria',
    },
    {
        prefijo: '358',
        nombre: 'Córdoba, Rio Cuarto',
    },
    {
        prefijo: '362',
        nombre: 'Chaco, Resistencia',
    },
    {
        prefijo: '364',
        nombre: 'Chaco, Presidencia Roque Sáenz Peña',
    },
    {
        prefijo: '370',
        nombre: 'Formosa, Formosa',
    },
    {
        prefijo: '376',
        nombre: 'Misiones, Posadas',
    },
    {
        prefijo: '379',
        nombre: 'Corrientes, Corrientes',
    },
    {
        prefijo: '380',
        nombre: 'La Rioja, La Rioja',
    },
    {
        prefijo: '381',
        nombre: 'Túcuman, San Miguel de Tucumán',
    },
    {
        prefijo: '383',
        nombre: 'Catamarca, San Fernando del Valle de Catamarca',
    },
    {
        prefijo: '385',
        nombre: 'Santiago del Estero, Santiago del Estero',
    },
    {
        prefijo: '387',
        nombre: 'Salta, Salta',
    },
    {
        prefijo: '388',
        nombre: 'Jujuy, San Salvador de Jujuy',
    },
    {
        prefijo: '2202',
        nombre: 'Buenos Aires, Gonzalez Catan',
    },
    {
        prefijo: '2221',
        nombre: 'Buenos Aires, Magdalena',
    },
    {
        prefijo: '2223',
        nombre: 'Buenos Aires, Brandsen',
    },
    {
        prefijo: '2224',
        nombre: 'Buenos Aires, Glew',
    },
    {
        prefijo: '2225',
        nombre: 'Buenos Aires, Alejandro Korn',
    },
    {
        prefijo: '2226',
        nombre: 'Buenos Aires, Cañuelas',
    },
    {
        prefijo: '2227',
        nombre: 'Buenos Aires, Lobos',
    },
    {
        prefijo: '2229',
        nombre: 'Buenos Aires, Juan Maria Gutierrez',
    },
    {
        prefijo: '2241',
        nombre: 'Buenos Aires, Chascomus',
    },
    {
        prefijo: '2242',
        nombre: 'Buenos Aires, Lezama',
    },
    {
        prefijo: '2243',
        nombre: 'Buenos Aires, General Belgrano',
    },
    {
        prefijo: '2244',
        nombre: 'Buenos Aires, Las Flores',
    },
    {
        prefijo: '2245',
        nombre: 'Buenos Aires, Dolores',
    },
    {
        prefijo: '2246',
        nombre: 'Buenos Aires, Santa Teresita',
    },
    {
        prefijo: '2252',
        nombre: 'Buenos Aires, San Clemente del Tuyu',
    },
    {
        prefijo: '2254',
        nombre: 'Buenos Aires, Pinamar',
    },
    {
        prefijo: '2255',
        nombre: 'Buenos Aires, Villa Gesell',
    },
    {
        prefijo: '2257',
        nombre: 'Buenos Aires, Mar de Ajó',
    },
    {
        prefijo: '2261',
        nombre: 'Buenos Aires, Loberia',
    },
    {
        prefijo: '2262',
        nombre: 'Buenos Aires, Necochea',
    },
    {
        prefijo: '2264',
        nombre: 'Buenos Aires, Nicanor Olivera (Est. La Dulce)',
    },
    {
        prefijo: '2265',
        nombre: 'Buenos Aires, Coronel Vidal',
    },
    {
        prefijo: '2266',
        nombre: 'Buenos Aires, Balcarce',
    },
    {
        prefijo: '2267',
        nombre: 'Buenos Aires, General Juan Madariaga',
    },
    {
        prefijo: '2268',
        nombre: 'Buenos Aires, Maipú',
    },
    {
        prefijo: '2271',
        nombre: 'Buenos Aires, San Miguel del Monte',
    },
    {
        prefijo: '2272',
        nombre: 'Buenos Aires, José Juan Almeyra',
    },
    {
        prefijo: '2273',
        nombre: 'Buenos Aires, Carmen de Areco',
    },
    {
        prefijo: '2274',
        nombre: 'Buenos Aires, Carlos Spegazzini',
    },
    {
        prefijo: '2281',
        nombre: 'Buenos Aires, Azul',
    },
    {
        prefijo: '2283',
        nombre: 'Buenos Aires, Talpalqué',
    },
    {
        prefijo: '2284',
        nombre: 'Buenos Aires, Olavarria',
    },
    {
        prefijo: '2285',
        nombre: 'Buenos Aires, Laprida',
    },
    {
        prefijo: '2286',
        nombre: 'Buenos Aires, General La Madrid',
    },
    {
        prefijo: '2291',
        nombre: 'Buenos Aires, Miramar',
    },
    {
        prefijo: '2292',
        nombre: 'Buenos Aires, Benito Juarez',
    },
    {
        prefijo: '2296',
        nombre: 'Buenos Aires, Ayacucho',
    },
    {
        prefijo: '2297',
        nombre: 'Buenos Aires, Rauch',
    },
    {
        prefijo: '2302',
        nombre: 'Buenos Aires La Pampa, General Pico',
    },
    {
        prefijo: '2314',
        nombre: 'Buenos Aires, San Carlos de Bolivar',
    },
    {
        prefijo: '2316',
        nombre: 'Buenos Aires, Daireaux',
    },
    {
        prefijo: '2317',
        nombre: 'Buenos Aires, Nueve de Julio',
    },
    {
        prefijo: '2320',
        nombre: 'Buenos Aires, José C. Paz',
    },
    {
        prefijo: '2323',
        nombre: 'Buenos Aires, Lujan',
    },
    {
        prefijo: '2324',
        nombre: 'Buenos Aires, Mercedes',
    },
    {
        prefijo: '2325',
        nombre: 'Buenos Aires, San Andres de Giles',
    },
    {
        prefijo: '2326',
        nombre: 'Buenos Aires, San Antonio de Areco',
    },
    {
        prefijo: '2331',
        nombre: 'La Pampa, Realico',
    },
    {
        prefijo: '2333',
        nombre: 'La Pampa, Quemú Quemú,',
    },
    {
        prefijo: '2334',
        nombre: 'La Pampa, Eduardo Castex',
    },
    {
        prefijo: '2335',
        nombre: 'La Pampa, Caleufú',
    },
    {
        prefijo: '2336',
        nombre: 'Córdoba, Huinca Renancó',
    },
    {
        prefijo: '2337',
        nombre: 'Buenos Aires, América',
    },
    {
        prefijo: '2338',
        nombre: 'La Pampa, Victorica',
    },
    {
        prefijo: '2342',
        nombre: 'Buenos Aires, Bragado',
    },
    {
        prefijo: '2343',
        nombre: 'Buenos Aires, Norberto de La Riestra',
    },
    {
        prefijo: '2344',
        nombre: 'Buenos Aires, Saladillo',
    },
    {
        prefijo: '2345',
        nombre: 'Buenos Aires, 25 de Mayo',
    },
    {
        prefijo: '2346',
        nombre: 'Buenos Aires, Chivilcoy',
    },
    {
        prefijo: '2352',
        nombre: 'Buenos Aires, Chacabuco',
    },
    {
        prefijo: '2353',
        nombre: 'Buenos Aires Santa Fe, General Arenales',
    },
    {
        prefijo: '2354',
        nombre: 'Buenos Aires, Vedia',
    },
    {
        prefijo: '2355',
        nombre: 'Buenos Aires, Lincol',
    },
    {
        prefijo: '2356',
        nombre: 'Buenos Aires, General Pinto',
    },
    {
        prefijo: '2357',
        nombre: 'Buenos Aires, Carlos Tejedor',
    },
    {
        prefijo: '2358',
        nombre: 'Buenos Aires, Los Toldos',
    },
    {
        prefijo: '2392',
        nombre: 'Buenos Aires, Trenque Lauquen',
    },
    {
        prefijo: '2393',
        nombre: 'Buenos Aires, Salazar',
    },
    {
        prefijo: '2394',
        nombre: 'Buenos Aires, Tres Lomas',
    },
    {
        prefijo: '2395',
        nombre: 'Buenos Aires, Carlos Casares',
    },
    {
        prefijo: '2396',
        nombre: 'Buenos Aires, Pehuajó',
    },
    {
        prefijo: '2473',
        nombre: 'Buenos Aires Santa Fe, Colon',
    },
    {
        prefijo: '2474',
        nombre: 'Buenos Aires, Salto',
    },
    {
        prefijo: '2475',
        nombre: 'Buenos Aires, Rojas',
    },
    {
        prefijo: '2477',
        nombre: 'Buenos Aires Santa Fe, Pergamino',
    },
    {
        prefijo: '2478',
        nombre: 'Buenos Aires, Arrecifes',
    },
    {
        prefijo: '2622',
        nombre: 'Mendoza, Tunuyán',
    },
    {
        prefijo: '2624',
        nombre: 'Mendoza, Uspallata',
    },
    {
        prefijo: '2625',
        nombre: 'Mendoza, General Alvear',
    },
    {
        prefijo: '2626',
        nombre: 'Mendoza, La Paz',
    },
    {
        prefijo: '2646',
        nombre: 'San Juan, Villa San Agustin',
    },
    {
        prefijo: '2647',
        nombre: 'San Juan, San José de Jáchal',
    },
    {
        prefijo: '2648',
        nombre: 'San Juan, Calingasta',
    },
    {
        prefijo: '2651',
        nombre: 'San Luis, San Francisco del Monte de Oro',
    },
    {
        prefijo: '2652',
        nombre: 'San Luis',
    },
    {
        prefijo: '2655',
        nombre: 'San Luis, La Toma',
    },
    {
        prefijo: '2656',
        nombre: 'San Luis, Tilisarao',
    },
    {
        prefijo: '2657',
        nombre: 'San Luis, Villa Mercedes',
    },
    {
        prefijo: '2658',
        nombre: 'San Luis, Buena Esperanza',
    },
    {
        prefijo: '2901',
        nombre: 'Tierra del Fuego, Ushuaia',
    },
    {
        prefijo: '2902',
        nombre: 'Santa Cruz, Rio Turbio',
    },
    {
        prefijo: '2903',
        nombre: 'Chubut, Rio Mayo',
    },
    {
        prefijo: '2920',
        nombre: 'Tierra del Fuego, Ushuaia',
    },
    {
        prefijo: '2901',
        nombre: 'Rio Negro Buenos Aires, Viedma',
    },
    {
        prefijo: '2921',
        nombre: 'Buenos Aires, Coronel Dorrego',
    },
    {
        prefijo: '2922',
        nombre: 'Buenos Aires, Coronel Pringles',
    },
    {
        prefijo: '2923',
        nombre: 'Buenos Aires, Pigué',
    },
    {
        prefijo: '2924',
        nombre: 'Buenos Aires La Pampa, Darregueira',
    },
    {
        prefijo: '2925',
        nombre: 'Buenos Aires La Pampa, Villa Iris',
    },
    {
        prefijo: '2926',
        nombre: 'Buenos Aires, Coronel Suarez',
    },
    {
        prefijo: '2927',
        nombre: 'Buenos Aires, Médanos',
    },
    {
        prefijo: '2928',
        nombre: 'Buenos Aires, Pedro Luro',
    },
    {
        prefijo: '2929',
        nombre: 'Buenos Aires, Guamini',
    },
    {
        prefijo: '2931',
        nombre: 'Rio Negro La Pampa, Rio Colorado',
    },
    {
        prefijo: '2932',
        nombre: 'Buenos Aires, Punta Alta',
    },
    {
        prefijo: '2933',
        nombre: 'Buenos Aires, Huanguelén',
    },
    {
        prefijo: '2934',
        nombre: 'Rio Negro, San Antonio Oeste',
    },
    {
        prefijo: '2935',
        nombre: 'Buenos Aires, Rivera',
    },
    {
        prefijo: '2936',
        nombre: 'Buenos Aires, Carhué',
    },
    {
        prefijo: '2940',
        nombre: 'Rio Negro, Ingeniero Jacobacci',
    },
    {
        prefijo: '2942',
        nombre: 'Neuquén Rio Negro, Zapata',
    },
    {
        prefijo: '2945',
        nombre: 'Chubut, Esquel',
    },
    {
        prefijo: '2946',
        nombre: 'Rio Negro, Choele Choel',
    },
    {
        prefijo: '2948',
        nombre: 'Neuquén, Chos',
    },
    {
        prefijo: '2952',
        nombre: 'La Pampa, General Acha',
    },
    {
        prefijo: '2953',
        nombre: 'La Pampa, Macachin',
    },
    {
        prefijo: '2954',
        nombre: 'La Pampa, Santa Rosa',
    },
    {
        prefijo: '2962',
        nombre: 'Santa Cruz, Puerto San Julián',
    },
    {
        prefijo: '2963',
        nombre: 'Santa Cruz, Perito Moreno',
    },
    {
        prefijo: '2964',
        nombre: 'Tierra del Fuego, Rio Grande',
    },
    {
        prefijo: '2966',
        nombre: 'Santa Cruz, Rio Gallegos',
    },
    {
        prefijo: '2972',
        nombre: 'Neuquén, San Martin de los Andes',
    },
    {
        prefijo: '2982',
        nombre: 'Buenos Aires, Orense',
    },
    {
        prefijo: '2983',
        nombre: 'Buenos Aires, Tres Arrollos',
    },
    {
        prefijo: '3327',
        nombre: 'Buenos Aires, Benavidez',
    },
    {
        prefijo: '3329',
        nombre: 'Buenos Aires, San Pedro',
    },
    {
        prefijo: '3382',
        nombre: 'Santa Fe Buenos Aires Cordoba, Rufino',
    },
    {
        prefijo: '3385',
        nombre: 'Córdoba, Laboulaye',
    },
    {
        prefijo: '3387',
        nombre: 'Córdoba, Buchardo',
    },
    {
        prefijo: '3388',
        nombre: 'Buenos Aires, General Villegas',
    },
    {
        prefijo: '3400',
        nombre: 'Santa Fe, Villa Constitución',
    },
    {
        prefijo: '3401',
        nombre: 'Santa Fe, El Trébol',
    },
    {
        prefijo: '3402',
        nombre: 'Santa Fe, Arroyo Seco',
    },
    {
        prefijo: '3404',
        nombre: 'Santa Fe, San Carlos Centro',
    },
    {
        prefijo: '3405',
        nombre: 'Santa Fe, San Javier',
    },
    {
        prefijo: '3406',
        nombre: 'Santa Fe, San Jorge',
    },
    {
        prefijo: '3407',
        nombre: 'Buenos Aires, Ramallo',
    },
    {
        prefijo: '3408',
        nombre: 'Santa Fe, San Cristobal',
    },
    {
        prefijo: '3409',
        nombre: 'Santa Fe, Moisés Ville',
    },
    {
        prefijo: '3435',
        nombre: 'Entre Rios, Nogoyá',
    },
    {
        prefijo: '3436',
        nombre: 'Entre Rios, Victoria',
    },
    {
        prefijo: '3437',
        nombre: 'Entre Rios, La Paz',
    },
    {
        prefijo: '3438',
        nombre: 'Entre Rios, Bovril',
    },
    {
        prefijo: '3442',
        nombre: 'Entre Rios, Concepción del Uruguay',
    },
    {
        prefijo: '3444',
        nombre: 'Entre Rios, Gualeguay',
    },
    {
        prefijo: '3445',
        nombre: 'Entre Rios, Rosario del Tala',
    },
    {
        prefijo: '3446',
        nombre: 'Entre Rios, Gualeguaychú',
    },
    {
        prefijo: '3447',
        nombre: 'Entre Rios, Cólon',
    },
    {
        prefijo: '3454',
        nombre: 'Entre Rios, Federal',
    },
    {
        prefijo: '3455',
        nombre: 'Entre Rios, Villaguay',
    },
    {
        prefijo: '3456',
        nombre: 'Entre Rios, Chajari',
    },
    {
        prefijo: '3458',
        nombre: 'Entre Rios, San Jose de Feliciano',
    },
    {
        prefijo: '3460',
        nombre: 'Santa Fe, Santa Teresa',
    },
    {
        prefijo: '3462',
        nombre: 'Santa Fe, Vendao Tuerto',
    },
    {
        prefijo: '3463',
        nombre: 'Córdoba, Canals',
    },
    {
        prefijo: '3464',
        nombre: 'Santa Fe, Casilda',
    },
    {
        prefijo: '3465',
        nombre: 'Santa Fe, Firmat',
    },
    {
        prefijo: '3466',
        nombre: 'Santa Fe, Barracas',
    },
    {
        prefijo: '3467',
        nombre: 'Córdoba Santa Fe, Cruz Alta',
    },
    {
        prefijo: '3468',
        nombre: 'Córdoba Santa Fe, Corral de Bustos',
    },
    {
        prefijo: '3469',
        nombre: 'Santa Fe, Acebal',
    },
    {
        prefijo: '3471',
        nombre: 'Santa Fe, Cañada de Gómez',
    },
    {
        prefijo: '3472',
        nombre: 'Córdoba, Marcos Juarez',
    },
    {
        prefijo: '3476',
        nombre: 'Santa Fe, San Lorenzo',
    },
    {
        prefijo: '3482',
        nombre: 'Santa Fe, Reconquista',
    },

    {
        prefijo: '3483',
        nombre: 'Santa Fe, Vera',
    },
    {
        prefijo: '3487',
        nombre: 'Buenos Aires, Zárate',
    },
    {
        prefijo: '3489',
        nombre: 'Buenos Aires, Campana',
    },
    {
        prefijo: '3491',
        nombre: 'Santa Fe Santiago del Estero, Ceres',
    },
    {
        prefijo: '3492',
        nombre: 'Santa Fe, Rafaela',
    },
    {
        prefijo: '3493',
        nombre: 'Santa Fe, Sunchales',
    },
    {
        prefijo: '3496',
        nombre: 'Santa Fe, Esperanza',
    },
    {
        prefijo: '3497',
        nombre: 'Santa Fe, Llambi Cambell',
    },
    {
        prefijo: '3498',
        nombre: 'Santa Fe, San Justo',
    },
    {
        prefijo: '3521',
        nombre: 'Córdoba, Déan Funes',
    },
    {
        prefijo: '3522',
        nombre: 'Córdoba, Villa de Maria',
    },
    {
        prefijo: '3524',
        nombre: 'Córdoba, Villa del Totoral',
    },
    {
        prefijo: '3525',
        nombre: 'Córdoba, Jesús María',
    },
    {
        prefijo: '3532',
        nombre: 'Córdoba, Oliva',
    },
    {
        prefijo: '3533',
        nombre: 'Córdoba, Las Varillas',
    },
    {
        prefijo: '3537',
        nombre: 'Córdoba, Bell Ville',
    },
    {
        prefijo: '3541',
        nombre: 'Córdoba, Villa Carlos Paz',
    },
    {
        prefijo: '3542',
        nombre: 'Córdoba, Salsacate',
    },
    {
        prefijo: '3543',
        nombre: 'Córdoba, Cordoba(Arguello)',
    },
    {
        prefijo: '3544',
        nombre: 'Córdoba, Villa Dolores',
    },
    {
        prefijo: '3546',
        nombre: 'Córdoba, Santa Rosa de Calamuchita',
    },
    {
        prefijo: '3547',
        nombre: 'Córdoba, Alta Gracia',
    },
    {
        prefijo: '3548',
        nombre: 'Córdoba, La Falda',
    },
    {
        prefijo: '3548',
        nombre: 'Córdoba, La Falda',
    },
    {
        prefijo: '3549',
        nombre: 'Córdoba, Cruz del Eje',
    },
    {
        prefijo: '3562',
        nombre: 'Córdoba Santa Fe, Morteros',
    },
    {
        prefijo: '3563',
        nombre: 'Córdoba, Balnearia',
    },
    {
        prefijo: '3564',
        nombre: 'Córdoba Santa Fe, San Francisco',
    },
    {
        prefijo: '3571',
        nombre: 'Córdoba, Rio Tercero',
    },
    {
        prefijo: '3572',
        nombre: 'Córdoba, Rio Segundo',
    },
    {
        prefijo: '3573',
        nombre: 'Córdoba, Villa del Rosario',
    },
    {
        prefijo: '3574',
        nombre: 'Córdoba, Rio Primero',
    },
    {
        prefijo: '3575',
        nombre: 'Córdoba, La Puerta',
    },
    {
        prefijo: '3576',
        nombre: 'Córdoba, Arroyito',
    },
    {
        prefijo: '3582',
        nombre: 'Córdoba San Luis, Sampacho',
    },
    {
        prefijo: '3583',
        nombre: 'Córdoba, Vicuña Mackenna',
    },
    {
        prefijo: '3584',
        nombre: 'Córdoba, La Carlota',
    },
    {
        prefijo: '3585',
        nombre: 'Córdoba, Adelia Maria',
    },
    {
        prefijo: '3711',
        nombre: 'Formosa, Ingeniero Juárez',
    },
    {
        prefijo: '3715',
        nombre: 'Formosa Chaco, Las Lomitas',
    },
    {
        prefijo: '3716',
        nombre: 'Formosa, Comandante Fontana',
    },
    {
        prefijo: '3718',
        nombre: 'Formosa, Clorinda',
    },
    {
        prefijo: '3721',
        nombre: 'Chaco, Charadai',
    },
    {
        prefijo: '3725',
        nombre: 'Chaco, General José de San Martin',
    },
    {
        prefijo: '3731',
        nombre: 'Chaco, Charata',
    },
    {
        prefijo: '3734',
        nombre: 'Chaco, Presidencia de la Plaza',
    },
    {
        prefijo: '3735',
        nombre: 'Chaco, Villa Ángela',
    },
    {
        prefijo: '3741',
        nombre: 'Misiones, Bernardo de Irigoyen',
    },
    {
        prefijo: '3743',
        nombre: 'Misiones, Puerto Rico',
    },
    {
        prefijo: '3751',
        nombre: 'Misiones, Eldorado',
    },
    {
        prefijo: '3754',
        nombre: 'Misiones, Leandro N. Alem',
    },
    {
        prefijo: '3755',
        nombre: 'Misiones, Oberá',
    },
    {
        prefijo: '3756',
        nombre: 'Corrientes, Santo Tomé',
    },
    {
        prefijo: '3757',
        nombre: 'Misiones, Puerto Iguazú',
    },
    {
        prefijo: '3758',
        nombre: 'Misiones Corrientes, Apóstoles',
    },
    {
        prefijo: '3772',
        nombre: 'Corrientes, Paso de los Libres',
    },
    {
        prefijo: '3773',
        nombre: 'Corrientes, Mercedes',
    },
    {
        prefijo: '3774',
        nombre: 'Corrientes, Curuzú Cuatia',
    },
    {
        prefijo: '3775',
        nombre: 'Corrientes, Monte Caseros',
    },
    {
        prefijo: '3777',
        nombre: 'Corrientes, Goya',
    },
    {
        prefijo: '3781',
        nombre: 'Corrientes, Caá Cati',
    },
    {
        prefijo: '3782',
        nombre: 'Corrientes, Saladas',
    },
    {
        prefijo: '3786',
        nombre: 'Corrientes, Ituzangó',
    },
    {
        prefijo: '3821',
        nombre: 'La Rioja, Chepes',
    },
    {
        prefijo: '3825',
        nombre: 'La Rioja, Chilecito',
    },
    {
        prefijo: '3826',
        nombre: 'La Rioja, Chamical',
    },
    {
        prefijo: '3827',
        nombre: 'La Rioja, Aimogasta',
    },
    {
        prefijo: '3832',
        nombre: 'Catamarca Santiago del Estero, Recreo',
    },
    {
        prefijo: '3835',
        nombre: 'Catamarca, Andalgalá',
    },
    {
        prefijo: '3837',
        nombre: 'Catamarca, Tinogasta',
    },
    {
        prefijo: '3838',
        nombre: 'Catamarca, Santa María',
    },
    {
        prefijo: '3841',
        nombre: 'Santiago del Estero, Monte Quemado',
    },
    {
        prefijo: '3843',
        nombre: 'Santiago del Estero, Quimili',
    },
    {
        prefijo: '3844',
        nombre: 'Santiago del Estero, Añatuya',
    },
    {
        prefijo: '3845',
        nombre: 'Santiago del Estero, Loreto',
    },
    {
        prefijo: '3846',
        nombre: 'Santiago del Estero, Tintina',
    },
    {
        prefijo: '3854',
        nombre: 'Santiago del Estero, Frias',
    },
    {
        prefijo: '3855',
        nombre: 'Santiago del Estero, Suncho Corral',
    },
    {
        prefijo: '3856',
        nombre: 'Santiago del Estero, Villa Ojo de Agua',
    },
    {
        prefijo: '3857',
        nombre: 'Santiago del Estero, Bandera',
    },
    {
        prefijo: '3858',
        nombre: 'Santiago del Estero, Termas de Rio Hondo',
    },
    {
        prefijo: '3861',
        nombre: 'Santiago del Estero, Nueva Esperanza',
    },
    {
        prefijo: '3862',
        nombre: 'Tucumán, Trancas',
    },
    {
        prefijo: '3863',
        nombre: 'Tucumán, Monteros',
    },
    {
        prefijo: '3865',
        nombre: 'Tucumán, Concepción',
    },
    {
        prefijo: '3867',
        nombre: 'Tucumán, Tafi del Valle',
    },
    {
        prefijo: '3868',
        nombre: 'Salta, Cafayate',
    },
    {
        prefijo: '3869',
        nombre: 'Tucuman, Ranchillos y San Miguel',
    },
    {
        prefijo: '3873',
        nombre: 'Salta, Tartagal',
    },
    {
        prefijo: '3876',
        nombre: 'Salta, San José de Metán',
    },
    {
        prefijo: '3877',
        nombre: 'Salta Chaco, Joaquín Víctor González,',
    },
    {
        prefijo: '3878',
        nombre: 'Salta, Orán',
    },
    {
        prefijo: '3885',
        nombre: 'Jujuy Salta, La Quiaca',
    },
    {
        prefijo: '3886',
        nombre: 'Jujuy, libertador General San Martin',
    },
    {
        prefijo: '3887',
        nombre: 'Jujuy, Humahuaca',
    },
    {
        prefijo: '3888',
        nombre: 'Jujuy, San Pedro de Jujuy',
    },
    {
        prefijo: '3891',
        nombre: 'Tucumán, Graneros',
    },
    {
        prefijo: '3892',
        nombre: 'Tucumán, Amaicha del Valle',
    },
    {
        prefijo: '3894',
        nombre: 'Tucumán, Burruyacú',
    },
/* 

    {
        prefijo: '370',
        nombre: 'Formosa',
    },
    {
        prefijo: '221',
        nombre: 'La Plata',
    },
    {
        prefijo: '380',
        nombre: 'La Rioja',
    },
    {
        prefijo: '261',
        nombre: 'Mendoza',
    },
    {
        prefijo: '299',
        nombre: 'Neuquén',
    },
    {
        prefijo: '343',
        nombre: 'Paraná',
    },
    {
        prefijo: '376',
        nombre: 'Posadas',
    },
    {
        prefijo: '280',
        nombre: 'Rawson',
    },
    {
        prefijo: '362',
        nombre: 'Resistencia',
    },
    {
        prefijo: '2966',
        nombre: 'Rio Gallegos',
    },
    {
        prefijo: '387',
        nombre: 'Salta',
    },
    {
        prefijo: '383',
        nombre: 'San Fernando del Valle de Catamarca',
    },
    {
        prefijo: '264',
        nombre: 'San Juan',
    },
    {
        prefijo: '266',
        nombre: 'San Luis',
    },
    {
        prefijo: '381',
        nombre: 'San Miguel de Tucuman',
    },
    {
        prefijo: '388',
        nombre: 'San Salvador de Jujuy',
    },
    {
        prefijo: '342',
        nombre: 'Santa Fe',
    },
    {
        prefijo: '2954',
        nombre: 'Santa Rosa',
    },
    {
        prefijo: '385',
        nombre: 'Santiago del Estero',
    },
    {
        prefijo: '2920',
        nombre: 'Viedma',
    },
     {
        prefijo: '2901',
        nombre: 'Usuaia',
    }, */
];