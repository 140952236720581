const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const Bases = {
    text: 'Bases',
    link: '/bases',
    icon: 'icon-layers'
};

const Campañas = {
    text: 'Campañas',
    link: '/campanias',
    icon: 'icon-briefcase',
    /* submenu: [
        {
            text: "Nueva Campaña",
            link: "/campanias/nuevaCampania"
        },
        {
            text: "Consultar Campañas",
            link: "/campanias"
        },
        {
            text: "Calendarizacion",
            link: "/campanias/verCalendarizacion"
        }
    ], */
};
const BandejaEntrada = {
    text: 'Bandeja de Entrada',
    link: '/bandejaEntrada',
    icon: 'icon-layers'
};
const Usuarios = {
    text: 'Usuarios',
    link: '/usuarios',
    icon: 'icon-bell'
};
const Traza = {
    text: 'Traza',
    link: '/traza',
    icon: 'icon-anchor'
};
const Estadisticas = {
    text: 'Estadisticas',
    link: '/estadisticas',
    icon: 'icon-graph'
};

// const PlanesVigentes = {
//     text: 'PlanesVigentes',
//     link: '/planesVigentes',
//     icon: 'icon-info'
// };

const Normalizador = {
    text: 'Normalizador',
    link: '/normalizador',
    icon: 'fab fa-amazon'
};

const EstadisticasAdmin = {
    text: 'EstadisticasAdmin',
    link: '/estadisticasAdmin',
    icon: 'icon-graph'
};
const BandejaEntradaAdmin = {
    text: 'BandejaEntradaAdmin',
    link: '/bandejaEntradaAdmin',
    icon: 'icon-layers'
};
const EnviadoresAdmin = {
    text: 'EnviadoresAdmin',
    link: '/enviadoresAdmin',
    icon: 'fa fa-shield-alt'
};

const EmpresasAdmin = {
    text: 'EmpresasAdmin',
    link: '/empresasAdmin',
    icon: 'icon-anchor'
};

const CampaniasAdmin = {
    text: 'CampañasAdmin',
    link: '/campaniasAdmin',
    icon: 'icon-bell'
};

const FacturacionAdmin = {
    text: 'FacturacionAdmin',
    link: '/facturacionAdmin',
    icon: 'icon-briefcase'
};

const PruebasAdmin = {
    text: 'PruebaAdmin',
    link: '/pruebaAdmin',
    icon: 'icon-exclamation'
};

const PlanesAdmin = {
    text: 'PlanesAdmin',
    link: '/planesAdmin',
    icon: 'icon-exclamation'
};


const headingMas = {
    text: 'Mas',
    heading: true
};

const headingMásSuperAdmin = {
    text: 'Más',
    heading: true
};


const Material = {
    text: "Material",
    link: "/material",
    icon: "fa fa-shield-alt",
    submenu: [
        {
            text: "Cards",
            link: "/material/cards"
        },
        {
            text: "Forms",
            link: "/material/forms"
        },
        {
            text: "Inputs",
            link: "/material/inputs"
        },
        {
            text: "Lists",
            link: "/material/lists"
        },
        {
            text: "Whiteframe",
            link: "/material/whiteframe"
        },
        {
            text: "Colors",
            link: "/material/colors"
        },
        {
            text: "ng2Material",
            link: "/material/ngmaterial"
        }
    ],
    "alert": "new",
    "label": "badge badge-primary"
};

const Cliente = {
    text: 'Cliente',
    link: '/cliente',
    icon: 'icon-home'
};


const headingMain = {
    text: 'Menu',
    heading: true
};

export const menu = [
    headingMain,
    Home,
    Bases,
    Campañas,
    BandejaEntrada,
    headingMas,
    Traza,
    Usuarios,
    Normalizador,
    Estadisticas,
    // PlanesVigentes,
    EmpresasAdmin,
    EstadisticasAdmin,
    CampaniasAdmin,
    BandejaEntradaAdmin,
    FacturacionAdmin,
    headingMásSuperAdmin,
    EnviadoresAdmin,
    PlanesAdmin
 //   PruebasAdmin
 /*    Material,
    Cliente */
];
