import { NgModule } from '@angular/core';
import { IngresoComponent } from './ingreso/ingreso.component';
import { SharedModule } from '@shared/shared.module';
import { RegistroComponent } from './registro/registro.component';
import { RecuperoComponent } from './recupero/recupero.component';

@NgModule({
  declarations: [
    IngresoComponent,
    RegistroComponent,
    RecuperoComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    IngresoComponent
  ]
})
export class UsuarioModule { }
