export class BaseDeDatos {
    Id: number;
    Name: string;
    Description: string;
    UserId: number;
    CreationDate: Date;
    TraceId: number;
    CompanyId: number;

    archivo: FormData;
    constructor() {
    }
}